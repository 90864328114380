<template>
  <sgv-input-select
    :options="options"
    @input="emitInput"
    :validations="validations"
    v-bind="$attrs"
    :value="value"
    select="salesCredit">
    <template slot-scope="option">
      {{option.salesCredit}}
    </template>
  </sgv-input-select>
</template>

<script>
export default {
  name: 'DetailFormSalesCreditInput',
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    docId: {
      type: Number,
      required: true
    },
    select: {
      type: String,
      required: false
    },
    value: {
      type: [Object, String, Number],
      required: false
    },
    validations: {
      type: Array,
      required: false,
      default() {
        return [
          {value: false, text: ''}
        ]
      }
    },
    contact: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      options: [
        {salesCredit: 0}
      ],
    }
  },
  methods: {
    emitInput (value) {
      this.$emit('input', value)
    },
    setDefault () {
      this.options = [
        {salesCredit: 0}
      ]

      if (this.docId > 0) {
        this.options.push({salesCredit: this.value})
      } else if (this.docId === 0 && !this.contact) {
        this.emitInput(0)
      } else {
        const arr = this.contact.docConfigs.filter(v => v.type === 'salesCredit')
        if (arr.length > 0) {
          this.options = [...this.options, ...arr]
          if (this.docId == 0) {
            const found = arr.find(v => v.isDefault)
            if (found) this.emitInput(found.salesCredit)
            else this.emitInput(0)
          }
        }
      }
    }
  },
  watch: {
    contact: {
      handler () {
        this.setDefault()
      },
      immediate: true
    }
  },
}
</script>

<style lang="css" scoped>
</style>
